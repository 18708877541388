<template>
    <div class="experts-landing">
        <HeroBasic
            :heading="title"
            accentColor="grey"
            :richText="dek"
            class="page-heading"
            :showTopographic="true"
            :hasHeaderOffset="true"
        />

        <client-only>
            <search
                :page-language="pageLanguage"
                :index-name="`people_${pageLanguage}`"
                :filter="`isArchived != TRUE AND personDetails.personType = carnegieExpert AND center = ${center}`"
                :limit="400"
                :enabled-filters="{ topics: true, regions: true, years: false, experts: false }"
                has-filters
                sort-by="lastName"
                sort-direction="asc"
                disable-syndication-filter
                experts-page-filtering
                has-search
                :attributes-to-search="['title']"
                name-filter
                :search-placeholder="$t('Search by name...')"
            >
                <template #results="{ results }">
                    <template v-for="(result, index) in results">
                        <div v-if="result" class="result" :key="index">
                            <div class="person">
                                <Avatar
                                    v-if="result.avatar"
                                    :key="result.id"
                                    :url="result.path+'?lang='+pageLanguage"
                                    :image="result.avatar"
                                    size="medium"
                                    class="icon"
                                />
                                <Anchor :url="result.url+'?lang='+pageLanguage" class="details">
                                    <Typography as="h4" variant="h4-display" class="title">{{ result.title }} </Typography>
                                    <Typography as="div" variant="body-display-small-italic" class="role"
                                        >{{ result.personTitle }}
                                    </Typography>
                                </Anchor>
                            </div>

                            <div class="features">
                                <TagCluster
                                    :pageLanguage="pageLanguage"
                                    :items="[
                                        ...(result.primaryTopics?.map(topic => ({ ...topic, path: topic.url })) || []),
                                        ...(result.primaryRegions?.map(region => ({ ...region, path: region.url })) || [])
                                    ]"
                                />
                            </div>

                            <div v-if="showCenter(result.center)" class="centers">
                                <Icon name="center" class="center-icon" />
                                <Typography variant="label" class="center-title">
                                    {{
                                        $t(centerString(result.center), 1, {
                                            locale: pageLanguage,
                                        })
                                    }}
                                </Typography>
                            </div>
                        </div>
                    </template>
                </template>
            </search>
        </client-only>
    </div>
</template>

<script setup>
import centers from '@/constants/centers.ts';
const { pageLanguage } = usePageDataStore();

const props = defineProps({
    title: {
        type: String,
        default: '',
    },
    dek: {
        type: Object,
        default: () => {},
    },
    contentType: {
        type: String,
        default: '',
    },
    id: {
        type: String,
    },
    path: {
        type: String,
    },
    locale: {
        type: String,
        default: '',
    },
});

function showCenter(centers) {
    return centers?.filter((center) => center !== 'global').length;
}

function centerString(summaryCenters) {
    const centerNames = [];

    summaryCenters?.forEach((center) => {
        if (center !== 'global') {
            const centerName = centers.find(({ id }) => id === center);
            centerNames.push(centerName?.title);
        }
    });

    return centerNames.join(', ');
}

const headerAttributesStore = useHeaderAttributesStore();

const { currentCenter } = useCenters();
const center = currentCenter.value?.id;

const GTMCenter = useGTM();
const GTMLang = props.locale;
onMounted(() => {
    window.dataLayer.push({
        Center: GTMCenter.GTMCenter,
        Doctype: 'Experts Landing',
        Expert: '',
        'Taxonomy Tags Program': '',
        'Taxonomy Special Project': '',
        'Taxonomy Tags Regions': '',
        'Taxonomy Tags Topics': '',
        'Page Language': GTMLang,
        event: 'new_data_layer',
    });
    headerAttributesStore.updateHeaderStyleAttributes({ background: 'transparent' });
});
</script>

<style lang="scss" scoped>
.page-heading {
    background: color(grey, light);
    border-bottom: 0.1rem solid palette(border-light);
    margin-bottom: vertical-space(1);
}

.result {
    padding-top: vertical-space(1);
    padding-bottom: vertical-space(1);
    align-items: center;

    @include grid;
    @include media-query(phone) {
        display: block;
    }
    @include content-area;

    &:not(:last-child) {
        border-bottom: 1px solid palette(bg-gray-dark);
    }

    .person {
        grid-column: 1 / span 6;
        display: flex;
        gap: vertical-space(1);
        align-items: center;

        @include media-query(phone) {
            margin-bottom: vertical-space(1);
            flex-direction: row-reverse;
            justify-content: space-between;
        }

        .details {
            flex-grow: 1;
        }

        .title {
            color: color(blue, default, 0.8);
        }

        .role {
            color: color(noir, default, 0.6);
        }
    }

    .features {
        display: none;

        @include media-query(full) {
            display: block;
            grid-column: 7 / span 3;
            @include padding-tb(vertical-space(1));
        }
    }

    .centers {
        grid-column: 10 / span 3;
        display: flex;
        gap: 0.4rem;
        color: color(noir, default, 0.3);
        align-items: center;

        @include media-query(tablet) {
            grid-column: 8 / span 5;
        }

        .center-icon {
            flex-shrink: 0;
        }

        @include media-query(full) {
            @include padding-tb(vertical-space(1));
        }
    }
}
</style>
